import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthService } from './services/auth.service';
import { AuthGuardService } from './services/auth-guard.service';
import { UserProfileService } from './services/userprofile.service';
import { HomeComponent } from './components/home/home.component';
import { BrokerComponent } from './components/broker/broker.component';
import { BranchesComponent } from './components/branches/branches.component';
import { ProductConfigurationComponent } from './components/product-configuration/product-configuration.component';
import { ProductPricingComponent } from './components/product-pricing/product-pricing.component';
import { AlertComponent } from './components/alert/alert.component';
import { AuditTrailComponent } from './components/audit-trail/audit-trail.component';
import { AlertHistoryComponent } from './components/alert-history/alert-history.component';
import { ReportingComponent } from './components/reporting/reporting.component';
import { AmendmentsComponent } from './components/amendments/amendments.component';
import { LevelsOfCoverConfigurationComponent } from './components/levelsofcover-configuration/levelsofcover-configuration.component';
import { LevelOfCoverValidationComponent } from './components/level-of-cover-validation/level-of-cover-validation.component';
import { StationaryMaintenanceComponent } from './components/stationary-maintenance/stationary-maintenance.component';
import { SystemSettingComponent } from './components/settings/system-setting/system-setting.component';
import { InsurerComponent } from './components/insurer/insurer.component';
import { ProductInformationComponent } from './components/product-configuration/product-information/product-information.component';
import { BrokerDetailsComponent } from './components/broker/broker-details/broker-details.component';
import { ErrorHandlerComponent } from './shared/error-handler/error-handler.component';
import { BrokerGroupComponent } from './components/broker-group/broker-group.component';
import { BranchProductDetailComponent } from './components/branch-product-detail/branch-product-detail.component';
import { UserManagementComponent } from './components/user-management/user-management.component';
import { UserComponent } from './components/user/user.component';
import { Roles } from './models/enums/roles';
import { UpdatePolicyComponent } from './components/policy/update-policy.component';
import { UnSavedChangesGuard } from './shared/can-deactivate/un-saved-changes.guard';
import { UserAccessReportComponent } from './components/user-access-report/user-access-report.component';
import { CustomPricingReportComponent } from './components/custom-pricing-report/custom-pricing-report.component';
import { DeclarationsReportComponent } from './components/declarations-report/declarations-report.component';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { ReportsHistoryComponent } from './components/reports-history/reports-history.component';
import { ClaimsMadeReportComponent } from './components/claims-made-report/claims-made-report.component';
import { UnavailableComponent } from './shared/error-handler/unavailable.component';
import { PolicyImportReportComponent } from './components/policy-import-report/policy-import-report.component';

const routes: Routes = [
  { path: '', redirectTo: '/app', pathMatch: 'full' },
  {
    path: 'app',
    data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR, Roles.HOUSER, Roles.HODESIGN] },
    children: [
      {
        path: '', component: HomeComponent,
        data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR, Roles.HOUSER, Roles.HODESIGN] },
        canLoad: [AuthGuardService],
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        pathMatch: 'full'
      },
      {
        path: 'brokers',
        data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] },
        canLoad: [AuthGuardService],
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        children: [
          { path: 'broker', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: BrokerComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
          { path: 'broker/create', canLoad: [AuthGuardService], canActivate: [AuthGuardService], canDeactivate: [UnSavedChangesGuard], component: BrokerDetailsComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
          { path: 'broker/update/:brokerId', canLoad: [AuthGuardService], canActivate: [AuthGuardService], canDeactivate: [UnSavedChangesGuard], component: BrokerDetailsComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
          { path: 'branches', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: BranchesComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
          { path: 'broker-group', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: BrokerGroupComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
          { path: 'branches/create', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: BranchesComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
          { path: 'branches/update/:branchId', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: BranchesComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
        ]
      },
      {
        path: 'products',
        data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HODESIGN, Roles.HOSUPERVISOR] },
        canLoad: [AuthGuardService],
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        children: [
          { path: 'configuration', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: ProductConfigurationComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HODESIGN] } },
          { path: 'levels-of-cover/configuration', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: LevelsOfCoverConfigurationComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN] } },
          { path: 'level-of-cover-validation', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: LevelOfCoverValidationComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN] } },
          { path: 'pricing', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: ProductPricingComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN] } },
          { path: 'insurer', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: InsurerComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
          { path: 'product-information/create', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: ProductInformationComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HODESIGN] } },
          { path: 'product-information/update/:productId', canLoad: [AuthGuardService], canActivate: [AuthGuardService], canDeactivate: [UnSavedChangesGuard], component: ProductInformationComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HODESIGN] } },
          { path: 'branch-product-detail/:branchId', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: BranchProductDetailComponent, canDeactivate: [UnSavedChangesGuard], data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } }
        ]
      },
      {
        path: 'stationary-maintenance',
        canLoad: [AuthGuardService],
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        component: StationaryMaintenanceComponent,
        data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] }
      },
      {
        path: 'settings',
        data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HODESIGN] },
        canLoad: [AuthGuardService],
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        children: [
          { path: 'system-setting', canLoad: [AuthGuardService], canActivate: [AuthGuardService], canDeactivate: [UnSavedChangesGuard], component: SystemSettingComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HODESIGN] } }
        ]
      },
      {
        path: 'reporting',
        canLoad: [AuthGuardService],
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR, Roles.HOUSER] },
        children: [
          { path: 'amendments', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: AmendmentsComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR, Roles.HOUSER] } },
          { path: 'reports', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: ReportingComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR, Roles.HOUSER] } },
          { path: 'user-access-reports', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: UserAccessReportComponent, data: { roles: [Roles.HOSUPERVISOR, Roles.HOADMIN] } },
          { path: 'custom-pricing-reports', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: CustomPricingReportComponent, data: { roles: [Roles.HOSUPERVISOR, Roles.HOADMIN] } },
          { path: 'declarations', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: DeclarationsReportComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } }, //role to be confirmed
          { path: 'claims-made', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: ClaimsMadeReportComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
          { path: 'policy-imports', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: PolicyImportReportComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
        ]
      },
      {
        path: 'notifications',
        canLoad: [AuthGuardService],
        canActivate: [AuthGuardService],
        canActivateChild: [AuthGuardService],
        data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HODESIGN] },
        children: [
          { path: 'alert', canLoad: [AuthGuardService], canActivate: [AuthGuardService], canDeactivate: [UnSavedChangesGuard], component: AlertComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HODESIGN] } },
          { path: 'alerthistory', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: AlertHistoryComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HODESIGN] } },
        ]
      },
      { path: 'users', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: UserManagementComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
      { path: 'users/create', canLoad: [AuthGuardService], canActivate: [AuthGuardService], canDeactivate: [UnSavedChangesGuard], component: UserComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
      { path: 'users/user/:userId', canLoad: [AuthGuardService], canActivate: [AuthGuardService], canDeactivate: [UnSavedChangesGuard], component: UserComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } },
      {
        path: 'audit-trail',
        canLoad: [AuthGuardService],
        canActivate: [AuthGuardService],
        data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] },
        children: [
          { path: 'changes-history', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: AuditTrailComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR]  } },
          { path: 'reports-history', canLoad: [AuthGuardService], canActivate: [AuthGuardService], component: ReportsHistoryComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR]  } },
        ]
      },

      { path: 'update-policy', canLoad: [AuthGuardService], canActivate: [AuthGuardService], canDeactivate: [UnSavedChangesGuard], component: UpdatePolicyComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR] } }

    ]
  },
  { path: 'error', component: ErrorHandlerComponent, data: { roles: [Roles.HOSYSADMIN, Roles.HOADMIN, Roles.HOSUPERVISOR, Roles.HOUSER] } },
  {
    path: 'access-denied', component: AccessDeniedComponent
  },
  {
    path: 'app-unavailable', component: UnavailableComponent
  },
  //ALLWAYS AT THE LAST ONE
  {
    path: '**', // Wildcard route for all other invalid paths
    redirectTo: '/app',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    AuthService,
    UserProfileService,
    AuthGuardService
  ]
})
export class AppRoutingModule { }

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ExportFileType } from 'src/app/models/enums/report-type.enum';
import { ExternalBroker, PolicyImportSummaryResponse } from 'src/app/models/policy-import-report-response';
import { ReportService } from 'src/app/services/report.service';
import { ApiEndPoints } from 'src/app/shared/config/api-end-points';
import { NO_RESULTS_FOUND } from 'src/app/shared/constants/constants';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { HttpBaseService } from 'src/app/shared/services/http-base.service';
import { Utility } from 'src/app/utils/utility';

@Component({
  selector: 'app-policy-import-report',
  templateUrl: './policy-import-report.component.html',
  styleUrls: ['./policy-import-report.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('expanded', style({ height: '*', visibility: 'visible' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class PolicyImportReportComponent {

  displayedColumns: string[] = ['expandbutton', 'brokerName', 'totalFiles', 'totalRecords', 'totalErrors'];
  allOptionTxt: string = 'All';

  expandedElement: PolicyImportSummaryResponse[] = [];
  public formGroup!: FormGroup;

  minDateFrom: Date = new Date(1999, 0, 1);
  maxDateFrom: Date = Utility.addDays(new Date(), 60);

  minDateTo: Date = new Date(1999, 0, 1);
  maxDateTo: Date = Utility.addDays(new Date(), 60);

  get isValidSubmit() {
    return !this.formGroup.invalid;
  }

  public get brokerForm() {
    return this.formGroup.get('brokerForm') as AbstractControl;
  }

  public get dateFrom() {
    return this.formGroup.get('dateFrom') as AbstractControl;
  }

  public get dateTo() {
    return this.formGroup.get('dateTo') as AbstractControl;
  }

  isShow = false;
  notFoundMessage = "";
  brokerNameExpanded: BehaviorSubject<string> = new BehaviorSubject('');
  brokerList: ExternalBroker[] = [];
  currentBrokers: string[] = [];
  actualSelected: string = "";

  dataSource: PolicyImportSummaryResponse[] = [];

  @ViewChild('allSelected') allSelected: MatOption;
  @ViewChild('matSelect') matSelect;

  constructor(private readonly formBuilder: FormBuilder,
    private readonly _reportService: ReportService,
    private readonly _dialogService: DialogService,
    private readonly httpService: HttpBaseService,
    private readonly _api: ApiEndPoints,
  ) { }

  async ngOnInit(): Promise<void> {

    const defaultDateFrom = moment(new Date().setHours(0, 0, 0, 0));
    const defaultDateTo = moment(new Date().setHours(0, 0, 0, 0));
    this.formGroup = this.formBuilder.group({
      brokerForm: ['', [Validators.required]],
      dateFrom: [defaultDateFrom, [Validators.required]],
      dateTo: [defaultDateTo, [Validators.required]],
    });

    this._reportService.getExternalBrokersForPolicyImportsReport().subscribe(
      x => {
        this.brokerList = x.sort((a, b) => a.name.localeCompare(b.name));
      }
    )

    this.brokerForm.valueChanges.subscribe(() => this.reset());
    this.dateFrom.valueChanges.subscribe(() => this.reset());
    this.dateTo.valueChanges.subscribe(() => this.reset());

  }

  toggleSelectionBroker(values: string[]) {
    let selected = (values.filter(x => !this.currentBrokers.includes(x))).concat(this.currentBrokers.filter(x => !values.includes(x)));
    if (selected.includes(this.allOptionTxt)) {
      if (this.allSelected.selected) {
        let ids = this.brokerList.map(({ key }) => key)
        this.brokerForm.setValue([this.allOptionTxt, ...ids]);
        this.actualSelected = this.allOptionTxt;
      } else {
        this.brokerForm.setValue([]);
        this.actualSelected = this.matSelect.triggerValue;
      }
    }
    else {
      let ids: string[] = this.brokerForm.value;
      const index = ids.indexOf(this.allOptionTxt);
      if (index > -1) {
        ids.splice(index, 1);
        this.brokerForm.setValue([...ids]);
      }

      if (ids.length == this.brokerList.length) {
        this.brokerForm.setValue([this.allOptionTxt, ...ids]);
        this.actualSelected = this.allOptionTxt;
      }
      else {
        this.actualSelected = this.matSelect.triggerValue;
      }
    }
    this.currentBrokers = this.brokerForm?.value;
  }

  isExpanded(row: any): string {
    if (!this.expandedElement) {
      return 'collapsed';
    }
    if (
      this.expandedElement.findIndex(x => x.brokerName == row.brokerName) !== -1
    ) {
      return 'expanded';
    }
    return 'collapsed';
  }

  toggleRow(broker) {
    if (this.expandedElement) {
      const index = this.expandedElement.findIndex(x => x.brokerName === broker.brokerName);
      if (index === -1) {
        this.brokerNameExpanded.next(broker.brokerName);
        this.expandedElement.push(broker);
      } else {
        this.expandedElement.splice(index, 1);
      }
    }
  }

  exportExcel() {
    this.export(ExportFileType.Excel);

  }

  exportCSV() {
    this.export(ExportFileType.Csv);
  }

  export(type: ExportFileType) {
    let url = this._api.getPolicyImportReport(this.brokerForm.value, this.dateFrom.value.format('YYYY-MM-DD'), this.dateTo.value.format('YYYY-MM-DD'), true, type);
    let fileName = 'PolicyImportReport';

    this.httpService.downloadFile(url).subscribe(
      (data: BlobPart) => {
        const blob = new Blob([data], { type: this.getContentType(type) });
        let fileURL = window.URL.createObjectURL(blob);
        let link = document.createElement('a');
        link.style.display = "none";
        link.href = fileURL;
        link.download = `${fileName} ${new Date().toLocaleDateString("en-UK")}`;
        link.target = '_blank';
        link.click();

        setTimeout(() => {
          link.remove();
          window.URL.revokeObjectURL(fileURL);
        }, 100);
      });
  }

  reset() {
    this.isShow = false;
    this.notFoundMessage = "";
    this.brokerNameExpanded.next("");
    this.expandedElement = [];
    this.dataSource = [];
    this.brokerNameExpanded = new BehaviorSubject('');
  }

  onSubmit() {
    if (this.formGroup.valid && this.validDates()) {
      this.reset();

      this._reportService.getPolicyImportSummaryReport(this.brokerForm.value, this.dateFrom.value.format('YYYY-MM-DD'), this.dateTo.value.format('YYYY-MM-DD'))
        .subscribe(x => {
          this.dataSource = x;
          if (this.dataSource.length > 0) {
            this.isShow = true;
          }
          else {
            this.isShow = false;
            this.notFoundMessage = NO_RESULTS_FOUND;
          }
        })
    }
  }

  validDates() {
    let valid = false;
    if (this.dateFrom.value && this.dateTo.value) {
      if (moment(this.dateFrom.value) > moment(this.dateTo.value)) {
        const errorMessage = 'The Date From is greater than the Date To. Please check and re-enter the dates.';
        this._dialogService.openErrorDialog({ title: 'Error', message: errorMessage });
      } else {
        valid = true;
      }
    }
    return valid;
  }

  getContentType(fileType: ExportFileType) {
    switch (fileType) {
      case ExportFileType.Pdf:
        return "application/pdf";

      case ExportFileType.Csv:
        return "text/csv";

      case ExportFileType.Excel:
        return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

      default:
        return '';
    }
  }

}
